<!-- 培训班级类别 -->
<template>
    <div class="ClassCategory">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" label-width="70px">
            <el-form-item label="搜索">
                <el-input v-model="form.search" placeholder="请输入关键字"></el-input>
            </el-form-item>
            <el-form-item label="选择学科">
                <el-select v-model="form.subject" placeholder="请选择学科" :clearable="true">
                    <el-option v-for="item in form.subjectOpt" :key="item.id" :label="item.course_name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="选择">
                <el-select v-model="form.choice" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.choiceOption" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <div class="" style="margin-left: 25px;margin-bottom: 10px;margin-top: 5px;">
                <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="query">
                    查询
                </el-button>
                <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                    重置
                </el-button>
            </div>
        </el-form>
        <div class="" style="margin-left: 21px;">
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
                <!-- 刷新 -->
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;" @click="newlyAdded">
                新增
            </el-button>
        </div>
        <!-- 列表 表格 -->
        <el-table :data="listData" border stripe style="width:97.9%;flex: 1;" height="650" @sort-change="abc">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="category_status" sortable="custom" label="状态" align="center" width="100">
                <template slot-scope="scope">
                    <span v-if="scope.row.category_status==1">准备</span>
                    <span v-else-if="scope.row.category_status==2">可用</span>
                    <span v-else-if="scope.row.category_status==3">停用</span>
                </template>
            </el-table-column>
            <el-table-column prop="number" sortable="custom" label="编码" align="center" width="150">
            </el-table-column>
            <el-table-column prop="course" sortable="custom" label="学科" align="center" width="120">
            </el-table-column>
            <el-table-column prop="category_name" sortable="custom" label="名称" align="center" width="210">
            </el-table-column>
            <el-table-column prop="resources" sortable="custom" label="主责教师" align="center" width="120">
            </el-table-column>
            <el-table-column prop="tuition_incidentals" sortable="custom" label="标准学杂费" align="center" width="200">
            </el-table-column>
            <el-table-column prop="tuition" sortable="custom" label="学费金额" align="center" width="200">
            </el-table-column>
            <el-table-column prop="incidentals" sortable="custom" label="杂费金额" align="center" width="200">
            </el-table-column>
            <el-table-column prop="category_size" sortable="custom" label="每班人数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="lecture_number" sortable="custom" label="授课次数" align="center" width="150">
            </el-table-column>
            <el-table-column prop="start_time" sortable="custom" label="启用日期" align="center" width="200">
            </el-table-column>
            <el-table-column prop="end_time" sortable="custom" label="停用日期" align="center" width="200">
            </el-table-column>
            <el-table-column  label="操作" align="center" fixed="right" width="360">
                <template slot-scope="scope">
                    <el-button icon="el-icon-edit" size="mini" style="background:#18BC9C;color: #fff;"
                        @click="editList(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button size="mini" style="background: #18BC9C;color: #fff;"
                        @click="classData(scope.$index, scope.row)">
                        班级
                    </el-button>
                    <el-button size="mini" style="background: #18BC9C;color: #fff;"
                        @click="teacherData(scope.$index, scope.row)">
                        授课老师
                    </el-button>
                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="delt(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20,50,100,200,500,1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    search: "", //搜索
                    subject: "", //选择学科
                    subjectOpt: [], //学科数据
                    choice: "", //选择
                    choiceOption: [{
                            value: '0',
                            label: '全部'
                        },
                        {
                            value: '1',
                            label: '准备'
                        },
                        {
                            value: '2',
                            label: '启用'
                        },
                        {
                            value: '3',
                            label: '停用'
                        }
                    ] //选择选项
                },
                listData: [], //列表数据
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
                arr:[]
            }
        },
        created() {
            // 选择学科
            this.$request({
                url: "/api/course/list",
                method: "POST",
                data: {
                    page: 1,
                    limit: 100
                }
            }).then(res => {
                // console.log(res);
                // console.log("选择学科", res.data.list)
                this.form.subjectOpt = res.data.list
            })


            this.getList()

        },
        activated() {
            this.getList()
        },
        methods: {
            // 列表
            getList() {
                this.$request({
                    url: '/api/classcategory/list',
                    method: 'POST',
                    data: {
                        page: this.currentPage, //页数
                        limit: this.currentLimit, //条数 
                        sort:this.arr
                    }
                }).then(res => {
                    console.log(res, "列表")
                    this.listData = res.data.list
                    this.total = res.data.count
                })
            },
            handleSizeChange(val) {
                this.currentLimit = val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            // 刷新
            refresh() {

                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addClassCategory"
                })

            },
            classData(index, row) { //跳转班级
                this.$router.push({
                    path: "classData",
                    query:{
                        class_category_id:row.id
                    }
                })
            },
            teacherData(index, row) { //跳转授课老师
                this.$router.push({
                    path: "teacherData",
                    query:{
                        class_category_id:row.id
                    }
                })
            },
            editList(index, row) { //列表数据编辑
                this.$router.push({
                    path: "editClassCategory",
                    query:{
                        id:row.id
                    }
                })
            },
            editTeacher(index, row) { //授课老师数据编辑
                this.$router.push({
                    path: "editTeacher"
                })
            },
            delt(index, row){//删除
                this.$confirm('此操作将永久删除该数据，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    acncelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$request({
                        url:'/api/classcategory/del',
                        method:'POST',
                        data:{
                            id: row.id
                        },
                    }).then(res=>{
                        if(res.code==1){
                            this.$message({
                              message: res.msg,
                              type: 'success'
                            });
                            this.listData.splice(index, 1)
                            this.getList()
                        }
                    })
                })
            },
            query() { //查询
                this.currentPage = 1
                this.getList()
            },
            Reset() { //重置
                this.form.choice = '' //选择
                this.form.subject = '' //学科
                this.form.search = ''
                this.getList()
            },
            // 姓名排序
            fn2(a, b){
                return a.resources.localeCompare(b.resources)
            },
            //排序
            abc(i){ 
                console.log(i)
                this.listData=[]
                this.arr=[]
                this.arr.push({
                    title:i.prop,
                    order:i.order
                })
                console.log(this.arr)
                this.getList()
            }
        }

    }
</script>

<style scoped="scoped">
    .ClassCategory {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        flex-wrap: wrap;
    }

    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }

    .form ::v-deep .el-form-item__label {
        color: #000000;
    }

    /*    .form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    } */

    /* 刷新按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }

    /* 新增按钮 */
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }

    /* 表格 */
    .el-table {
        margin: 20px;
    }

    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 30px;
    }

    /* tab按钮 */
    .btn {
        display: inline-block;
        border: 1px solid #999999;
        background-color: #FFFFFF;
        padding: 5px 15px;
        margin: 20px 0px;
        margin-right: 20px;
        font-size: 14px;
        border-radius: 3px;
        cursor: pointer;
    }

    /* 选中样式 */
    .active {
        background-color: #CDEEEB;
        border: 1px solid #29726C;
    }

    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
